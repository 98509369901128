import './form'
import './popup'


if($("div").is(".js-reviews-swiper")){ 
  var reviewsslider = new Swiper('.js-reviews-swiper', {
    spaceBetween: 20,		
    slidesPerView: 3,
    breakpoints: {
      992: {
      	slidesPerView: 2
      },
			768: {
				slidesPerView: 2
			},
			580: {
				slidesPerView: 1
			}
    },
    navigation: {
      nextEl: '.js-rev-next',
      prevEl: '.js-rev-prev',
    },
  });
}

$('.js-load-more').click(function (e) {
	e.preventDefault();
	let button = $(this);
	let paged = button.attr("data-paged");
	let max  = button.attr("data-max");	
	let posttype  = button.attr("data-posttype");
	let resultdiv  = button.attr("data-resultdiv");
	let postcount  = button.attr("data-postcount");
	let data={
		'max': max,
		'paged': paged,		
		'posttype': posttype,	
		'postcount': postcount,		
		'action': 'load_more_product'
	};
	console.log(data);
	$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		data: data,
		error: function (jqXHR, exception) {

		},
		beforeSend : function ( xhr ) {		
			button.text('Загружается...'); 
		},
		success : function( data ){
			button.html('<span> Загрузить еще </span>');
			console.log(data);
			paged++;

			if (paged == max) {
				button.hide();
			}
			if (data) {
				jQuery('.'+resultdiv).append(data);
				button.html('<span> Загрузить еще </span>');
				button.attr('data-paged', paged);
				console.log(data);
	
				
			} else {
				button.hide();
			}
		}
	});
});

$('.js-nav-btn').on('click',function(){
	var $this = $(this);
	var navBlock = $('.js-nav-block');
	$this.toggleClass('burger_active');
	navBlock.slideToggle();
	$('.main-menu').toggleClass('active'); 
});

$('.scrollbar-inner').scrollbar();


$('.faq-items .faq-item').on("click",function(){
	$('.faq-items .faq-item').removeClass("active");
	$(this).addClass('active');
});

$('.tariff-options__items a').on("click",function(e){
	e.preventDefault();
	$('.tariff-options__items a').removeClass("active");
	$(this).addClass("active");
	let pindex=$(this).data('index');
	
	$('.table__row .price').each(function(){
		$(this).text($(this).data('price'+pindex));
	});

});

$('.js-tarif-order').on("click",function(e){
	let name=$(this).closest('.table__section').find('.table__section__title').text();
	$('.js-form input[name=tarif]').val(name);
});

$('.js-tarif-order2').on("click",function(e){
	let name=$(this).closest('.tariff-item').find('.tariff-item__text').html();
	$('span.tarif-name').html(name);
	$('.js-form input[name=tarif]').val(name);

});